import uuid from 'uuid/v4';

import CalmCookie from '@/utils/CalmCookie';

interface Args {
	userIp?: string;
	browserLanguage?: string;
	path?: string;
	customHeaders?: {
		[key: string]: string;
	};
	requestCountry?: string;
}

interface Headers {
	'x-device-id': string;
	'x-device-version': string;
	'x-device-platform': string;
}

function getBaseHeaders(): Headers {
	let deviceId = CalmCookie.get('x-device-id');
	if (!deviceId) {
		deviceId = uuid();
		CalmCookie.set('x-device-id', deviceId);
	}
	return {
		'x-device-id': deviceId,
		'x-device-version': '1.0',
		'x-device-platform': 'partner-portal',
	};
}

function createReqHeaders({ userIp, path, browserLanguage = '', customHeaders = {}, requestCountry }: Args): {
	[key: string]: string;
} {
	const reqHeaders = {
		...getBaseHeaders(),
		...(requestCountry ? { 'x-request-country': requestCountry } : {}),
		...(userIp ? { 'X-Forwarded-For': userIp } : {}),
		...(browserLanguage ? { 'Accept-Language': browserLanguage } : {}),
		...(path ? { 'x-www-req-from': path } : {}),
		...customHeaders,
	};
	return reqHeaders;
}

export default createReqHeaders;
