import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useSWR, { KeyedMutator } from 'swr';

import { PaymentInfo } from '@/components/pages/Plan/PlanDetails/types';
import { setBannerMessage } from '@/store/actions';
import { isSelfServePlan } from '@/utils/SkuUtils';
import { GenericCalmError, isCalmError, CalmError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { useApi } from './useApi';
import { useOptionalPartner } from './usePartner';

export const usePaymentInfo = (): {
	data: PaymentInfo | undefined;
	mutate: KeyedMutator<PaymentInfo | undefined>;
	error: CalmError | undefined;
	isLoading: boolean;
} => {
	const { partnerId } = useParams<{ partnerId: string | undefined }>();
	const optionalPartner = useOptionalPartner(partnerId);
	const dispatch = useDispatch();
	const apiRequest = useApi();

	const { data, mutate, error, isLoading } = useSWR(
		`b2b/partners/${partnerId}/payment-instrument`,
		async (endpoint): Promise<PaymentInfo | undefined> => {
			try {
				if (
					!partnerId ||
					!optionalPartner?.data ||
					!isSelfServePlan(optionalPartner?.data.vouched_plan_sku)
				) {
					return;
				}
				const { data } = await apiRequest({
					endpoint,
					method: 'GET',
				});
				return data;
			} catch (err) {
				calmLogger.error('Teams : Error fetching payment instrument', { partnerId }, err);
				dispatch(
					setBannerMessage({
						message: 'Failed to fetch payment info',
						isError: true,
						flash: true,
					}),
				);
				throw err;
			}
		},
	);

	return {
		data,
		mutate,
		error: error ? (isCalmError(error) ? error : GenericCalmError) : undefined,
		isLoading,
	};
};
