import { Dictionary } from 'lodash';
import pickBy from 'lodash/pickBy';
import snakeCase from 'lodash/snakeCase';
import qs, { ParsedQs } from 'qs';

export const PARAMS_ALLOWLIST: Record<string, boolean> = {
	coupon: true, // To be used in the future for campaigns
	utm_medium: true,
	utm_source: true,
	utm_campaign: true,
	vanity_url: true,
	utm_content: true,
	utm_term: true,
};

/*
 * Filter query parameters from window and returns the allowlist of params
 * This is necessary to ensure we don't log sensitive tokens/excess information
 *
 * @return Object { query_param: String }
 */
function getPropertiesFromQueryParams(
	search: string,
): Dictionary<string | ParsedQs | string[] | ParsedQs[] | undefined> {
	const queryParamsObject = qs.parse(search.replace('?', ''));
	return pickBy(queryParamsObject, (value, key) => {
		const snakeCasedKey: string = snakeCase(key);
		return PARAMS_ALLOWLIST[snakeCasedKey];
	});
}

const _exports = { getPropertiesFromQueryParams };
export default _exports;
