import last from 'lodash/last';

import {
	ApiValidationError,
	isCalmError,
	LegacyApiValidationError,
	ValidationError,
} from '@/utils/apiRequest/errors';

const isLegacyApiValidationError = (
	info: LegacyApiValidationError | ApiValidationError,
): info is LegacyApiValidationError => Object.keys(info).includes('dataPath');

export function validationErrorParser(err: unknown): Array<ValidationError> {
	if (!isCalmError(err)) {
		return [];
	}
	if (!err?.data?.error) return [];
	// Api errors are formatted this way.
	// Other errors, such as type errors, won't have the data or error attribute
	// nested as an object
	const apiError = err?.data?.error;
	if (apiError?.code !== 'invalid_field') return [];
	if (!apiError?.info?.validationErrors) return [];
	const { validationErrors } = apiError.info;
	const validationErrorsArray = Array.isArray(validationErrors) ? validationErrors : [validationErrors];
	return validationErrorsArray.map((validationError: LegacyApiValidationError | ApiValidationError) => {
		if (isLegacyApiValidationError(validationError)) {
			const { dataPath, keyword, params } = validationError;
			// Parse out the id from `.body.field` format, where we know the last field is the
			// most meaningful field
			const id = last(dataPath.split('.'));
			return {
				id,
				code: keyword,
				params,
			};
		}
		const { path, type, context } = validationError;
		// the last field is the most meaningful field
		const id = last(path);
		return {
			id,
			code: type,
			params: context,
		};
	});
}
