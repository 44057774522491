import { JSONObject } from '@/types/json';

interface Params {
	limit?: number;
	comparison?: string;
	additionalProperty?: string;
}

export interface LegacyApiValidationError {
	dataPath: string;
	keyword: string;
	params?: Params;
}

export interface ApiValidationError {
	message: string;
	path: string[];
	type: string;
	context: JSONObject;
}

export interface ValidationError {
	code: string;
	id?: string;
	params?: Params;
}

interface ErrorCode {
	error: {
		code: string;
		info: JSONObject & {
			validationErrors?: [LegacyApiValidationError | ApiValidationError];
		};
		calmMessage?: string;
		message?: string;
		calmCode?: string;
		didTimeout?: string;
	};
}

export interface ApiError {
	headers: Record<string, string>;
	status: number;
	statusText: string;
	message?: string;
}

export const isApiError = (info: unknown): info is ApiError => {
	return (
		typeof info === 'object' &&
		info !== null &&
		Object.keys(info).includes('status') &&
		Object.keys(info).includes('statusText')
	);
};

export interface CalmError extends ApiError {
	data: ErrorCode;
}

export const isCalmError = (info: unknown): info is CalmError => {
	if (!isApiError(info)) {
		return false;
	}
	if (!Object.keys(info).includes('data')) {
		return false;
	}
	const infoWithData = info as ApiError & { data: unknown };
	if (
		!(
			typeof infoWithData.data === 'object' &&
			infoWithData.data !== null &&
			Object.keys(infoWithData.data).includes('error')
		)
	) {
		return false;
	}
	const infoWithDataError = infoWithData as ApiError & {
		data: { error: unknown };
	};
	return (
		typeof infoWithDataError.data.error === 'object' &&
		infoWithDataError.data.error !== null &&
		Object.keys(infoWithDataError.data.error).includes('code') &&
		Object.keys(infoWithDataError.data.error).includes('info')
	);
};

export function getCalmErrorOrError(err: unknown): Error | CalmError {
	if (isCalmError(err)) {
		return err;
	}
	if (err instanceof Error) {
		return err;
	}
	return new Error(String(err));
}

export const GenericCalmError: CalmError = {
	headers: {},
	status: 500,
	statusText: 'Internal Server Error',
	data: {
		error: {
			code: 'generic',
			info: {},
		},
	},
};

export function isReportNotFoundError(error?: Error | ApiError): error is CalmError {
	return Boolean(error && isCalmError(error) && error.data.error.code === 'b2b_partner_report_not_found');
}

export function isReportingPrivacyError(error?: Error | ApiError): error is CalmError {
	return !!(error && isCalmError(error) && error.data.error.code === 'b2b_partner_reporting_privacy_limit');
}

export interface OktaError {
	xhr: {
		status: number;
	};
	message: string;
	name: string;
}

export function isOktaError(info: unknown): info is OktaError {
	if (!(typeof info === 'object' && info !== null && Object.keys(info).includes('xhr'))) {
		return false;
	}
	const infoWithXhr = info as { xhr: unknown };
	if (
		!(
			typeof infoWithXhr.xhr === 'object' &&
			infoWithXhr.xhr !== null &&
			Object.keys(infoWithXhr.xhr).includes('status')
		)
	) {
		return false;
	}
	const infoWithXhrStatus = infoWithXhr as {
		xhr: { status: unknown };
	};
	return typeof infoWithXhrStatus.xhr.status === 'number';
}

export interface ErrorWithMessage {
	message: string;
}

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === 'object' &&
		error !== null &&
		'message' in error &&
		typeof (error as Record<string, unknown>).message === 'string'
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (isErrorWithMessage(maybeError)) return maybeError;

	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

export function getErrorMessage(error: unknown): string {
	return toErrorWithMessage(error).message;
}
