import { Duration, ProductSKUType, RenewalType, SKU, SubscriptionModel } from './SkuEnums';

export const ConfigToSku = {
	[SubscriptionModel.Custom]: {
		[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_CUSTOM,
		[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_CUSTOM,
		[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_CUSTOM,
		[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_CUSTOM,
		[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_CUSTOM,
		[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_CUSTOM,
		[ProductSKUType.LEGACY_COACHING]: SKU.LEGACY_COACHING_CUSTOM,
	},
	[Duration.OneMonth]: {
		NON_RENEW: {
			[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1M,
			[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1M,
			[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1M,
			[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1M,
			[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1M,
			[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1M,
		},
		SHOULD_RENEW: {
			[RenewalType.Autorenew]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1M_AUTORENEW,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1M_AUTORENEW,
			},
			[RenewalType.Renewable]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1M_RENEWABLE,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1M_RENEWABLE,
			},
		},
	},
	[Duration.ThreeMonth]: {
		NON_RENEW: {
			[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_3M,
			[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_3M,
			[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_3M,
			[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_3M,
			[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_3M,
			[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_3M,
		},
		SHOULD_RENEW: {
			[RenewalType.Autorenew]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_3M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_3M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_3M_AUTORENEW,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_3M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_3M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_3M_AUTORENEW,
			},
			[RenewalType.Renewable]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_3M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_3M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_3M_RENEWABLE,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_3M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_3M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_3M_RENEWABLE,
			},
		},
	},
	[Duration.SixMonth]: {
		NON_RENEW: {
			[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_6M,
			[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_6M,
			[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_6M,
			[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_6M,
			[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_6M,
			[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_6M,
		},
		SHOULD_RENEW: {
			[RenewalType.Autorenew]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_6M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_6M_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_6M_AUTORENEW,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_6M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_6M_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_6M_AUTORENEW,
			},
			[RenewalType.Renewable]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_6M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_6M_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_6M_RENEWABLE,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_6M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_6M_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_6M_RENEWABLE,
				[ProductSKUType.LEGACY_COACHING]: SKU.LEGACY_COACHING_6M_RENEWABLE,
			},
		},
	},
	[Duration.OneYear]: {
		NON_RENEW: {
			[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1Y,
			[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1Y,
			[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1Y,
			[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1Y,
			[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1Y,
			[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1Y,
			[ProductSKUType.LEGACY_COACHING]: SKU.LEGACY_COACHING_1Y,
		},
		SHOULD_RENEW: {
			[RenewalType.Autorenew]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1Y_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1Y_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1Y_AUTORENEW,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1Y_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1Y_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1Y_AUTORENEW,
			},
			[RenewalType.Renewable]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1Y_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1Y_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1Y_RENEWABLE,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1Y_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1Y_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1Y_RENEWABLE,
				[ProductSKUType.LEGACY_COACHING]: SKU.LEGACY_COACHING_1Y_RENEWABLE,
			},
		},
	},
	[Duration.Lifetime]: {
		NON_RENEW: {
			[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_LIFETIME,
			[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_LIFETIME,
			[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_LIFETIME,
			[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_LIFETIME,
			[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_LIFETIME,
			[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_LIFETIME,
		},
		// THIS	IS NOT USED BUT TS DOES NOT LIKE THAT ITS MISSING.
		// THERE IS LOGIC TO ENSURE THIS IS NEVER HIT
		SHOULD_RENEW: {
			[RenewalType.Autorenew]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1Y_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1Y_AUTORENEW,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1Y_AUTORENEW,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1Y_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1Y_AUTORENEW,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1Y_AUTORENEW,
			},
			[RenewalType.Renewable]: {
				[ProductSKUType.C4B_DIRECT]: SKU.PREMIUM_1Y_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BASE]: SKU.C4B_PAYOR_BASE_1Y_RENEWABLE,
				[ProductSKUType.C4B_PAYOR_BUYUP]: SKU.C4B_PAYOR_BUYUP_1Y_RENEWABLE,
				[ProductSKUType.CH_DIRECT]: SKU.CALM_HEALTH_1Y_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BASE]: SKU.CH_PAYOR_BASE_1Y_RENEWABLE,
				[ProductSKUType.CH_PAYOR_BUYUP]: SKU.CH_PAYOR_BUYUP_1Y_RENEWABLE,
				[ProductSKUType.LEGACY_COACHING]: SKU.LEGACY_COACHING_1Y_RENEWABLE,
			},
		},
	},
};
