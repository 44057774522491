import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import uuidv4 from 'uuid/v4';

import { LogEventArgs } from '@/hooks/analytics/types';
import apiRequest from '@/utils/apiRequest';

export async function postAnalyticsEvent({ eventName, eventProps }: LogEventArgs): Promise<AxiosResponse> {
	const eventTimestamp = DateTime.local();
	return apiRequest({
		endpoint: 'v1/events',
		method: 'POST',
		isHermes: true,
		body: {
			batch_id: uuidv4(),
			batch_timestamp: eventTimestamp,
			events: [
				{
					event_id: uuidv4(),
					event_timestamp: eventTimestamp,
					event_name: eventName,
					event_properties: eventProps,
				},
			],
			partners_handled: [
				'appsflyer',
				'amplitude',
				'facebook_control',
				'facebook_placebo',
				'facebook_testing',
			],
		},
	});
}
