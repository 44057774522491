/* eslint-disable no-redeclare */
import { useState, useCallback, useEffect, createContext, useContext } from 'react';
import { useDispatch } from 'react-redux';
import useSWR, { mutate } from 'swr';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useApi } from '@/hooks/api';
import { useUser } from '@/hooks/store';
import { setBannerMessage } from '@/store/actions';
import { Partner } from '@/types/store/reducers';
import { CalmError, getCalmErrorOrError } from '@/utils/apiRequest/errors';
import { calmLogger } from '@/utils/calmLogger';

import { ApiResponse } from './types';

export function partnerEndpoint<P extends string | undefined>(
	partnerId?: P,
): P extends string ? string : null;
export function partnerEndpoint(partnerId?: string): string | null {
	if (!partnerId) {
		return null;
	}
	return `b2b/partners/${partnerId}`;
}

export function useOptionalPartner(partnerId: undefined): undefined;
export function useOptionalPartner(partnerId: string): ApiResponse<Partner>;
export function useOptionalPartner(partnerId?: string): ApiResponse<Partner> | undefined;
export function useOptionalPartner(partnerId?: string): ApiResponse<Partner> | undefined {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { user } = useUser();

	const { data, error } = useSWR(
		partnerEndpoint(partnerId),
		async endpoint => {
			if (!user) {
				return null;
			}

			const isAdmin = user?.accessPolicy?.isAdmin;
			const allowedPartners = user?.accessPolicy?.allowedPartners;
			const isAllowedPartner = partnerId && allowedPartners?.includes(partnerId);
			const isAbleToFetchPartner = isAdmin || isAllowedPartner;

			try {
				if (!partnerId) {
					return null;
				}
				if (!isAbleToFetchPartner) {
					throw new Error('Not able to fetch partner');
				}

				const response = await apiRequest({ endpoint });
				if (!response.data) {
					throw new Error('Not able to fetch partner');
				}

				if (response.data.links)
					response.data.partner = { ...response.data.partner, links: response.data.links };
				return response;
			} catch (responseError) {
				calmLogger.error('Error when fetching partners', {}, responseError);
				dispatch(
					setBannerMessage({
						message: `Oops! Look like the account ${partnerId} is no longer active. If this is a mistake, please email your Calm Account Manager.`,
						isError: true,
						flash: true,
					}),
				);
				throw responseError;
			}
		},
		{ errorRetryCount: 0 },
	);
	if (!partnerId || !user) {
		return undefined;
	}

	const partner = (data?.data?.partner ?? data?.data) as Partner;
	return { data: partner, error, loading: !data && !error };
}

export function usePartner(partnerId: string): ApiResponse<Partner> {
	return useOptionalPartner(partnerId);
}

export function updatePartnerCache(partner: Partner): Promise<{ data: Partner } | undefined> {
	return mutate(`b2b/partners/${partner.id}`, { data: partner }, false);
}

export function updatePartnerCacheById(partnerId: string): Promise<void> {
	return mutate(`b2b/partners/${partnerId}`);
}

export function useTransitionSelfServePartner(
	partnerId: string,
): [() => Promise<void>, { loading: boolean; error: CalmError | Error | undefined }] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);

	async function transition(): Promise<void> {
		logEvent('Partner Portal : Transition Self Serve Partner to Managed Partner: Clicked');
		try {
			setLoading(true);
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/transition`,
				method: 'PATCH',
				body: {
					ignore_existing_subscriptions: true,
				},
			});
		} catch (err) {
			setError(getCalmErrorOrError(err));
			dispatch(
				setBannerMessage({
					message: `Oops! An error occurred for partner ${partnerId} while transitioning.`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}

	return [transition, { error, loading }];
}

export interface RequestLogoAccess {
	(): Promise<void>;
}

export function useRequestLogoAccess(
	partnerId: string,
): [RequestLogoAccess, ApiResponse<{ success: boolean }>] {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const { logEvent } = useAnalytics();
	const [data, setData] = useState<{ success: boolean } | undefined>(undefined);
	const [error, setError] = useState<CalmError | Error | undefined>(undefined);
	const [loading, setLoading] = useState<boolean>(false);
	// Reset state if partnerId changes
	useEffect(() => {
		setData(undefined);
		setError(undefined);
		setLoading(false);
	}, [partnerId]);

	const requestLogoAccess: RequestLogoAccess = useCallback(async () => {
		try {
			setLoading(true);
			logEvent('Partner Portal : DTC Logo : Requested');
			await apiRequest({
				endpoint: `b2b/partners/${partnerId}/branding-request`,
				method: 'POST',
			});
			setData({ success: true });
		} catch (err) {
			setError(getCalmErrorOrError(err));
			setData(undefined);
			dispatch(
				setBannerMessage({
					message: `Oops! An error occurred while requesting to upload a logo.`,
					isError: true,
					flash: true,
				}),
			);
			throw err;
		} finally {
			setLoading(false);
		}
	}, [apiRequest, dispatch, partnerId, logEvent]);

	return [requestLogoAccess, { data, loading, error }];
}

// The context will only ever be provided with a real partner,
// but typing requires us to provide a default here.
export const PartnerContext = createContext<Partner | undefined>(undefined);
export const useDefinedPartner = (): Partner => {
	const partner = useContext(PartnerContext);
	if (!partner) {
		throw new Error('Partner was requested, but it was not loaded in the context');
	}
	return partner;
};
