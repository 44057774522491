import { PropsWithChildren, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

// We only load the SPA wrapper so that we can get separate bundles while continuing to support
// our code that depends on react-router.
//
// Porting this code to next.js is the preferred strategy
const Spa = ({ children }: PropsWithChildren<{}>): JSX.Element => {
	// Used for development mode to add in StaticRouter below
	const [isClientSide, setIsClientSide] = useState(false);
	useEffect(() => setIsClientSide(true), []);

	if (!isClientSide) {
		// eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
		const { StaticRouter } = require('react-router');

		return <StaticRouter location="/">{children}</StaticRouter>;
	}

	return <BrowserRouter>{children}</BrowserRouter>;
};

export default Spa;
