import MobileDetect from 'mobile-detect';

import { LogEventArgs } from '@/hooks/analytics/types';

import { calmLogger } from '../calmLogger';
import { postAnalyticsEvent, postAnalyticsProperties } from './endpoints';

async function _logCalmEventProperties(): Promise<void> {
	// set whether mobile
	const md = new MobileDetect(window.navigator.userAgent);
	const deviceType = md.mobile() ? 'mobile' : 'desktop';

	try {
		await postAnalyticsProperties(deviceType);
	} catch (err) {
		calmLogger.error('Error in logCalmEventProperties postAnalyticsProperties', {}, err);
	}
}

export function logCalmEventProperties(): void {
	_logCalmEventProperties().catch(err => calmLogger.error('Error in logCalmEventProperties', {}, err));
}

async function _logCalmEvent({ eventName, eventProps }: LogEventArgs): Promise<void> {
	try {
		await postAnalyticsEvent({ eventName, eventProps });
	} catch (err) {
		calmLogger.error('Error in logCalmEvent postAnalyticsEvent', {}, err);
	}
}

export function logCalmEvent(args: LogEventArgs): void {
	_logCalmEvent(args).catch(err => calmLogger.error('Error in logCalmEvent', {}, err));
}
