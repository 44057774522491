import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

import { EnvConfig } from '@/env_config';

import createReqHeaders from './createReqHeaders';

export * from './validationErrorParser';

declare global {
	interface Window {
		apiEndpoint: string;
	}
}

interface CustomHeaders {
	[key: string]: string;
}

export interface ApiRequestArgs {
	endpoint: string;
	method?: 'POST' | 'GET' | 'PUT' | 'DELETE' | 'PATCH';
	customHeaders?: CustomHeaders;
	body?: AxiosRequestConfig['data'];
	params?: AxiosRequestConfig['params'];
	requestCountry?: string;
	isHermes?: boolean;
	timeout?: number;
}

const { apiUrl, hermesEndpoint } = EnvConfig;

// Throw an error on any response 400 or above
// We consider these to be fatal errors from the server
axios.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		const httpStatus = error?.response?.status;
		const isTimeout = error.code === 'ECONNABORTED';
		if (isTimeout) {
			const timeoutError = new Error('CLIENT_TIMEOUT');
			return Promise.reject(timeoutError);
		}

		if (httpStatus >= 400) {
			return Promise.reject(error.response);
		}

		return Promise.resolve(error.response);
	},
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function apiRequest<T = any>({
	endpoint,
	method,
	body,
	customHeaders = {},
	isHermes = false,
	params,
	timeout = 80 * 1000,
}: ApiRequestArgs): Promise<AxiosPromise<T>> {
	const baseUrl = isHermes ? hermesEndpoint : apiUrl;
	const url = `${baseUrl}/${endpoint}`;
	const headers = createReqHeaders({ customHeaders });
	const request = {
		method,
		url,
		timeout,
		headers,
		data: body,
		params,
	};
	return axios({
		...request,
	});
}

export default apiRequest;
